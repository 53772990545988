import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Loading from "../../../../common/components/Loading";
import PageTitle from "../../../../common/components/PageTitle";
import { Panel } from "../../../../common/components/Panel";
import Typography from "../../../../common/components/Typography";
import { Helmet } from "react-helmet";
import api from "../../../../services/api";

import { Avatar, Button, Card, Checkbox, Chip, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, MenuItem, Switch, TextField } from "@material-ui/core";
import { BeneficConfig } from "./interfaces";
import { BenefitCategory, BenefitSubcategory, Partner } from "./interfacess";
import { mode } from "mathjs";
import AddIcon from "@mui/icons-material/Add";
import * as S from "./styles";
import { Stack } from "@mui/material";
import { useHistory, useParams } from 'react-router-dom';
import { ThemeContext } from "styled-components";
import { GridWrapper, SectionWrapper, WrapperBack } from "./styles";
import AuthContext from "../../../../common/components/hooks/Auth";
import EditarProdutoImage from "./EditarEventoImage";
import BenefitBannerUpload from "../NewBenefitsBanner/BenefitBannerUpload";
import EditarEventoImage from "./EditarEventoImage";
import { AxiosResponse } from "axios";


interface Errors {
  [field: string]: string;
}

export interface Evento {
  id: string;
  nome: string;
  descricao?: string;
  companyid: string;
  horainicio: string;  // Formato TIME (HH:mm:ss)
  horafim: string;     // Formato TIME (HH:mm:ss)
  datainicio: string;  // Formato DATE (YYYY-MM-DD)
  datafim?: string;    // Formato DATE (YYYY-MM-DD), pode ser opcional
  foto?: string;       // URL ou caminho do arquivo da foto
  capacidademax: string;
  banner?: string;     // URL ou caminho do arquivo do banner
  modalidade: string;
  rua?: string;
  cidade?: string;
  cep?: string;
  numero?: string;
  bairro?: string;
  uf?: string;
  link?: string;
  publicoalvo?: string;
  categoriaevento?: string;
  valor?: string;
}

const initialState: Evento = {
  id: "",
  nome: "",
  descricao: "",
  companyid: "",
  horainicio: "",
  horafim: "",
  datainicio: "",
  datafim: "",
  capacidademax: "",
  modalidade: "",
  rua: "",
  cidade: "",
  cep: "",
  numero: "",
  bairro: "",
  uf: "",
  link: "",
  publicoalvo: "",
  categoriaevento: "",
  valor: ""
};
interface EventoResponse {
  data: Evento
}


const EditarEvento: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const { selectedCompany, ...context } = useContext(AuthContext);
  const theme = useContext(ThemeContext);
  const [benefit, setBenefit] = useState(initialState);
  const [benefitLogo, setBenefitLogo] = useState<File>(null);
  const [banner, setBanner] = useState<File>(null);
  const [docs, setDocs] = useState<File[]>(null);
  const [pago, setPago] = useState(false);
  const [nome, setNome] = useState<string>(initialState.nome);
  const [descricao, setDescricao] = useState<string>(initialState.descricao);
  const [companyid, setCompanyid] = useState<string>(initialState.companyid);
  const [horainicio, setHorainicio] = useState<string>(initialState.horainicio);
  const [horafim, setHorafim] = useState<string>(initialState.horafim);
  const [datainicio, setDatainicio] = useState<string>(initialState.datainicio);
  const [datafim, setDatafim] = useState<string>(initialState.datafim);
  const [capacidademax, setCapacidademax] = useState<string>(initialState.capacidademax);
  const [rua, setRua] = useState<string>(initialState.rua);
  const [cidade, setCidade] = useState<string>(initialState.cidade);
  const [cep, setCep] = useState<string>(initialState.cep);
  const [numero, setNumero] = useState<string>(initialState.numero);
  const [bairro, setBairro] = useState<string>(initialState.bairro);
  const [uf, setUf] = useState<string>(initialState.uf);
  const [link, setLink] = useState<string>(initialState.link);
  const [publicoalvo, setPublicoalvo] = useState<string>(initialState.publicoalvo);
  const [categoriaevento, setCategoriaevento] = useState<string>(initialState.categoriaevento);
  const [valor, setValor] = useState<string>(initialState.valor);
  const [modalidade, setModalidade] = useState("");
  const [idEdit, setIdEdit] = useState(0)

  const { id } = useParams<{ id: string }>()
  const handleChangeBenefitData = (obj: Object): void => {
    setBenefit(prev => ({
      ...prev,
      ...obj
    }));
  }

  const history = useHistory();
  const [inputValue, setInputValue] = useState<string>('');
  const [formErrors, setFormErrors] = useState<Errors>({});

  const setErrorMessage = async (obj: Errors) => {
    setFormErrors(prev => ({
      ...prev,
      ...obj,
    }));
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPago(event.target.checked);
  };

  const getBenefitInfos = async () => {
    try {
      const { data } = await api.get(`/evento/${id}`,);
      console.log("beneeeeee", data);
      setIdEdit(data.data.id)
      setNome(data.data.nome);
      setDescricao(data.data.descricao);
      setCompanyid(data.data.companyid);
      setHorainicio(data.data.horainicio);
      setHorafim(data.data.horafim);
      setDatainicio(data.data.datainicio);
      setDatafim(data.data.datafim);
      setCapacidademax(data.data.capacidademax);
      setModalidade(data.data.modalidade);
      setRua(data.data.rua);
      setCidade(data.data.cidade);
      setCep(data.data.cep);
      setNumero(data.data.numero);
      setBairro(data.data.bairro);
      setUf(data.data.uf);
      setLink(data.data.link);
      setPublicoalvo(data.data.publicoalvo);
      setCategoriaevento(data.data.categoriaevento);
      setValor(data.data.valor);
      setPago(data.data.pago)



    } catch (e) {

      console.log(e)

    } finally {
      setLoading(false);
    }
  }




  const validateData = () => {
    let hasError = false;

    setErrorMessage({});
    return hasError;
  }

  const postBenefit = async () => {
    if (validateData()) return;

    try {
      const formData = new FormData();

      const benefitData = {
        nome: nome,
        descricao: descricao || "",
        horainicio: horainicio,
        horafim: horafim,
        datainicio: datainicio,
        datafim: datafim || "",
        capacidademax: (capacidademax),
        modalidade: modalidade,
        rua: rua || "",
        cidade: cidade || "",
        cep: cep || "",
        numero: numero || "",
        bairro: bairro || "",
        uf: uf || "",
        link: link || "",
        publicoalvo: publicoalvo || "",
        categoriaevento: categoriaevento || "",
        valor: valor || "",
        pago: pago ? true : false
      };

      const response: AxiosResponse<EventoResponse> = await api.put(`/update/evento/${idEdit}`, benefitData);


      resetForm();
      alert("Produto criado com sucesso!");
      history.push("/evento");

      if (benefitLogo) {
        // Cria um FormData para o upload da foto
        const formData = new FormData();
        formData.append('foto', benefitLogo);

        // Envio da foto
        await api.post(`/sign_up/eventos/${response.data.data.id}/foto`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        alert("Foto enviada com sucesso!");
      }

      if (banner) {
        // Cria um FormData para o upload da foto
        const formData = new FormData();
        formData.append('foto', banner);

        // Envio da foto
        await api.post(`/sign_up/eventos/${response.data.data.id}/banner`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        alert("Foto enviada com sucesso!");
      }


    } catch (e) {
      console.log(e);
    }
  }

  const resetForm = (): void => {
    setBenefit(initialState);
    setBenefitLogo(null);
  }
  useEffect(() => {
    getBenefitInfos();

  }, [id]);

  const content = loading ? (
    <Loading />
  ) : (
    <>
      <SectionWrapper>

        <GridWrapper>
          <WrapperBack>
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "2%", marginBottom: "5%", paddingRight: "2%", paddingLeft: "2%" }}>
              <div>
                <PageTitle
                  title="Novo Evento"
                  subtitle="Aqui você pode criar um novo entendo."
                />
              </div>
              <Stack direction="row" spacing={1}>
                <Chip label="Eventos"
                  style={{
                    backgroundColor: theme.colors.primary,
                    color: theme.colors.primaryContrast,
                    fontSize: "12px",
                    padding: "7px 7px",
                    fontWeight: "bold",
                    marginRight: "2%"
                  }}
                  onClick={() => history.push(`/disponibilizar/produto`)}
                />
                <Chip label="Produtos Disponiveis"
                  style={{
                    backgroundColor: theme.colors.primary,
                    color: theme.colors.primaryContrast,
                    fontSize: "12px",
                    padding: "7px 7px",
                    fontWeight: "bold",
                    marginRight: "2%"
                  }}
                  onClick={() => history.push(`/disponivel/lojinha`)}
                />

              </Stack>
            </div>

            <Grid container spacing={3} style={{ padding: "1rem" }}>
              <Grid item xs={12} md={4} style={{ marginTop: "-3%" }}>
                <div style={{ textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", height: "50%" }}>
                  <EditarEventoImage onChangeImage={setBenefitLogo} src="" />
                </div>
              </Grid>

              <Grid item xs={12} md={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      id="produto"
                      name="produto"
                      fullWidth
                      autoFocus
                      label="Nome"
                      autoComplete="Nome"
                      onChange={(e) =>
                        setNome(e.target.value)
                      }
                      value={nome}
                      size="small"
                    />
                    {formErrors.produto && (
                      <S.ErrorMessage>
                        {formErrors.produto}
                      </S.ErrorMessage>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      id="estoque"
                      fullWidth
                      name="estoque"
                      label="Publico alvo"
                      autoComplete="estoque"
                      onChange={(e) =>
                        setPublicoalvo(e.target.value)
                      }
                      value={publicoalvo}
                      size="small"
                    />
                    {formErrors.estoque && (
                      <S.ErrorMessage>
                        {formErrors.estoque}
                      </S.ErrorMessage>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      id="estoque"
                      fullWidth
                      name="estoque"
                      label="Capacidade do evento"
                      autoComplete="estoque"
                      onChange={(e) =>
                        setCapacidademax(e.target.value)
                      }
                      value={capacidademax}
                      size="small"
                    />
                    {formErrors.estoque && (
                      <S.ErrorMessage>
                        {formErrors.estoque}
                      </S.ErrorMessage>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      id="categoria"
                      fullWidth
                      select
                      name="categoria"
                      label="Categoria"
                      autoComplete="pagamento"
                      onChange={(e) =>
                        setCategoriaevento(e.target.value)
                      }
                      value={categoriaevento}
                      size="small"
                    >
                      <MenuItem value="webinar">Webinar</MenuItem>
                      <MenuItem value="treinamento">Treinamento</MenuItem>
                      <MenuItem value="conferencia">Conferência</MenuItem>
                      <MenuItem value="seminario">Seminário</MenuItem>
                      <MenuItem value="forum">Fórum</MenuItem>
                      <MenuItem value="mesa_redonda">Mesa redonda</MenuItem>
                      <MenuItem value="simposio">Simpósio</MenuItem>
                      <MenuItem value="palestra">Palestra</MenuItem>
                      <MenuItem value="feira">Feira</MenuItem>
                      <MenuItem value="exposicao">Exposição</MenuItem>
                      <MenuItem value="lancamento_de_produto">Lançamento de produto</MenuItem>
                      <MenuItem value="encontro_de_networking">Encontro de networking</MenuItem>
                      <MenuItem value="hackathon">Hackathon</MenuItem>
                      <MenuItem value="retiro">Retiro</MenuItem>
                      <MenuItem value="evento_beneficente">Evento beneficente</MenuItem>
                      <MenuItem value="festival">Festival</MenuItem>
                      <MenuItem value="sessao_de_brainstorming">Sessão de brainstorming</MenuItem>
                      <MenuItem value="painel_de_discussao">Painel de discussão</MenuItem>
                      <MenuItem value="cerimonia_de_premiacao">Cerimônia de premiação</MenuItem>
                      <MenuItem value="audiencia_publica">Audiência pública</MenuItem>
                      <MenuItem value="ensaio_aberto">Ensaio aberto</MenuItem>
                      <MenuItem value="workshop_pratico">Workshop prático</MenuItem>

                    </TextField>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      variant="outlined"
                      id="modalidade"
                      fullWidth
                      select
                      name="modalidade"
                      label="Modalidade"
                      autoComplete="Modalidade"
                      onChange={(e) =>
                        setModalidade(e.target.value)
                      }
                      value={modalidade}
                      size="small"
                    >
                      <MenuItem value="presencial">Presencial</MenuItem>
                      <MenuItem value="ead">EAD</MenuItem>
                      <MenuItem value="hibrido">Hibrido</MenuItem>
                    </TextField>
                  </Grid>
                  {modalidade && (
                    <>
                      {['ead', 'hibrido'].includes(modalidade) && (
                        <Grid item xs={12} sm={6}>
                          <TextField
                            variant="outlined"
                            id="link"
                            fullWidth
                            label="Link"
                            value={link}
                            onChange={(e) => setLink(e.target.value)}

                            size="small"
                          />
                        </Grid>
                      )}
                      {['presencial', 'hibrido'].includes(modalidade) && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              id="endereco"
                              fullWidth
                              label="Endereço"
                              value={rua}
                              onChange={(e) => setRua(e.target.value)
                              }
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              id="cidade"
                              fullWidth
                              label="Cidade"
                              value={cidade}
                              onChange={(e) => setCidade(e.target.value)
                              }
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              id="cep"
                              fullWidth
                              label="CEP"
                              value={cep}
                              onChange={(e) => setCep(e.target.value)
                              }
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              id="numero"
                              fullWidth
                              label="Número"
                              value={numero}
                              onChange={(e) => setNumero(e.target.value)}
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              id="bairro"
                              fullWidth
                              label="Bairro"
                              value={bairro}
                              onChange={(e) => setBairro(e.target.value)
                              }
                              size="small"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              variant="outlined"
                              id="uf"
                              fullWidth
                              label="UF"
                              value={uf}
                              onChange={(e) => setUf(e.target.value)
                              }
                              size="small"
                            />
                          </Grid>
                        </>

                      )}
                    </>
                  )}
                  <Grid container xs={12} style={{ padding: "1rem" }}>
                    <Grid item xs={12} sm={6} style={{ display: "flex" }}>
                      <TextField
                        id="modalidade"
                        type="checkbox"
                        name="modalidade"
                        onChange={handleCheckboxChange}
                        value={pago}
                      >
                      </TextField>
                      <label>Pago?</label>
                    </Grid>

                    {pago && (
                      <Grid item xs={12} sm={6}>
                        <TextField
                          id="valor"
                          variant="outlined"
                          label="Valor por ingresso"
                          type="text"
                          value={valor}
                          onChange={(e) => setValor(e.target.value)
                          }
                          fullWidth
                          size="small"

                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label>Data de inicio</label>
                    <TextField
                      variant="outlined"
                      id="estoque"
                      fullWidth
                      name="estoque"
                      type="date"
                      autoComplete="estoque"
                      onChange={(e) =>
                        setDatainicio(e.target.value)
                      }
                      value={datainicio}
                      size="small"
                    />
                    {formErrors.estoque && (
                      <S.ErrorMessage>
                        {formErrors.estoque}
                      </S.ErrorMessage>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label>Horario de inicio</label>

                    <TextField
                      variant="outlined"
                      id="estoque"
                      fullWidth
                      type="time"
                      name="estoque"
                      autoComplete="estoque"
                      onChange={(e) =>
                        setHorainicio(e.target.value)
                      }
                      value={horainicio}
                      size="small"
                    />
                    {formErrors.estoque && (
                      <S.ErrorMessage>
                        {formErrors.estoque}
                      </S.ErrorMessage>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label>Data de encerramento</label>
                    <TextField
                      variant="outlined"
                      id="estoque"
                      fullWidth
                      name="estoque"
                      type="date"
                      autoComplete="estoque"
                      onChange={(e) =>
                        setDatafim(e.target.value)
                      }
                      value={datafim}
                      size="small"
                    />
                    {formErrors.estoque && (
                      <S.ErrorMessage>
                        {formErrors.estoque}
                      </S.ErrorMessage>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label>Hora de finalização</label>
                    <TextField
                      variant="outlined"
                      id="estoque"
                      fullWidth
                      type="time"
                      name="estoque"
                      autoComplete="estoque"
                      onChange={(e) =>
                        setHorafim(e.target.value)
                      }
                      value={horafim}
                      size="small"
                    />
                    {formErrors.estoque && (
                      <S.ErrorMessage>
                        {formErrors.estoque}
                      </S.ErrorMessage>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <TextField
                      variant="outlined"
                      id="shortdescription"
                      fullWidth
                      name="shortdescription"
                      multiline
                      rows={4}
                      label="Descrição"
                      autoComplete="municipio"
                      onChange={(e) =>
                        setDescricao(e.target.value)
                      }
                      value={descricao}
                      size="small"
                    />
                    {formErrors.shortdescription && (
                      <S.ErrorMessage>
                        {formErrors.shortdescription}
                      </S.ErrorMessage>
                    )}
                  </Grid>
                </Grid>
                <div style={{ marginTop: "15px" }}>
                  <BenefitBannerUpload
                    onChangeImage={setBanner}
                    src={null}
                  />
                </div>
              </Grid>
            </Grid>

            <div style={{ display: "flex", padding: "1.5rem" }}>
              <Chip label="Adicionar Produto"
                style={{
                  backgroundColor: theme.colors.primary,
                  color: theme.colors.primaryContrast,
                  fontSize: "12px",
                  padding: "7px 7px",
                  fontWeight: "bold",
                }}
                onClick={postBenefit}
              />
            </div>
          </WrapperBack>
        </GridWrapper>
      </SectionWrapper>

    </>
  );

  return (
    <>

      {content}
    </>
  );
};

export default EditarEvento;

